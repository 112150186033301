import React, {useState} from 'react'
import Modal from "react-responsive-modal";
import CustomInput from "../../../organizer/components/custom/CustomInput";
import {Button, DatePicker, Icon, Toggle} from "rsuite";
import TextStyleComp
    from "../components/TextStyleComp/TextStyleComp";
import {
    getDefaultTemplateValues, resetTemplateIntroScreenStyles
} from "../../InviteTemplatesFunctions";
import IntroScreenTemplateRenderLazy from "./IntroScreenTemplateRenderLazy";
import {useIntl} from "react-intl";
import {getTemplateSettingsById} from "../inviteTemplates";
import DateContainerStyleComp
    from "../components/DateContainerStyleComp/DateContainerStyleComp";
import {translateInviteTexts} from "../../../functions";
import {corporateWeddingEvents} from "../../../const";
import IntroButtonStyleComp from "../components/IntroButtonStyleComp/IntroButtonStyleComp";
import SVG_Walkthrough_Arrow from "../../../assets/icons/walkthrough_arrow.svg";
import Intro_screen_icon from "../../../organizer/assets/icons/invite-intro-screen.svg";
import Animation_Icon from "../../../organizer/assets/icons/invite-animation.svg";


export default function IntroScreenEditComp(
    {
        eventType,
        weddingData,
        saveWeddingData,
        templateDetails,
        setTemplateDetails,
        locations,
    }) {
    const intl = useIntl();

    const [showModalSection1, setShowModalSection1] = useState(false)
    const [showModalSection2, setShowModalSection2] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false)
    const settings = getTemplateSettingsById(templateDetails.id)


    const toggleModalIntro = (section) => {

        switch (section) {
            case 'introScreenSection1':
                setShowModalSection1(!showModalSection1)
                break
            case 'introScreenSection2':
                setShowModalSection2(!showModalSection2)
                break
            default:
                break
        }

    }

    const changeTemplateSectionValue = (section, index, value) => {
        let newTemplateDetails = templateDetails ? {...templateDetails} : {}
        newTemplateDetails[section][index] = value
        setTemplateDetails(newTemplateDetails)
    }
    const toggleStyle = {
        margin: "10px 0px",
        textAlign: 'center',
        maxWidth: "500px",
        alignSelf: 'center'
    }

    return (<div className={"EditInviteComp"}>

        {
            settings && settings.introScreen && settings.introScreen.templateId ?

                <>
                {!templateDetails.introScreenId ?
                    <div className={"section-info-cont"}>


                        <div className={"header-cont"}>
                            <div className={"title-col"}>
                                <div className={"title-1"}>
                                    Oferă o experiență
                                </div>
                                <div className={"title-2-small"}>
                                    INTERACTIVĂ
                                </div>
                            </div>
                            <div className={"icon-col"}>
                                <img src={Intro_screen_icon} alt=""/>
                            </div>
                        </div>

                        <div className={"description"}>
                            Ecranul introductiv îi întâmpină pe invitați cu o prezentare de ansamblu captivantă a
                            evenimentului tău, oferindu-le o modalitate intuitivă de a explora fiecare secțiune a
                            invitației.
                        </div>
                        <div className={"description"}>
                            În același timp, adaugă un strop de mister și anticipație, asemenea momentului magic când
                            deschizi un plic elegant. Este primul pas, atent gândit, care transformă invitația într-o
                            experiență memorabilă și sofisticată!
                        </div>

                        <div className={"footer-cont"}>
                            <div className={"cta-col"}>
                                <div className={"text-col"}>
                                    Activează opțiunea
                                </div>
                                <div className={"img-col"}>
                                    <img src={SVG_Walkthrough_Arrow} alt=""/>
                                </div>
                            </div>
                            <div className={"right-col"}></div>
                        </div>


                    </div>
                :null}

                    <div style={{marginBottom: '1rem'}}>
                        {intl.formatMessage({id: 'intro_screen.config.enable_question'})}
                        <br/>
                        <Toggle
                            style={toggleStyle}
                            checked={!!(templateDetails.introScreenId)}
                            size="lg"
                            checkedChildren={intl.formatMessage({id: 'general.yes'})}
                            unCheckedChildren={intl.formatMessage({id: 'general.no'})}
                            onChange={(value) => {
                                let newTemplateDetails = {...templateDetails}
                                if (value) {
                                    newTemplateDetails.introScreenId = settings.introScreen.templateId
                                    newTemplateDetails.introScreenConfiguration = settings.introScreen.config

                                    if (!newTemplateDetails.introScreenSection1)
                                        newTemplateDetails.introScreenSection1 = {}

                                    if (!newTemplateDetails.introScreenSection1.groomName)
                                        newTemplateDetails.introScreenSection1.groomName = templateDetails.section1.groomName

                                    if (!newTemplateDetails.introScreenSection1.groomNameStyles)
                                        newTemplateDetails.introScreenSection1.groomNameStyles = templateDetails.section1.groomNameStyles

                                    if (!newTemplateDetails.introScreenSection1.separator)
                                        newTemplateDetails.introScreenSection1.separator = templateDetails.section1.separator

                                    if (!newTemplateDetails.introScreenSection1.separatorStyles)
                                        newTemplateDetails.introScreenSection1.separatorStyles = templateDetails.section1.separatorStyles

                                    if (!newTemplateDetails.introScreenSection1.brideName)
                                        newTemplateDetails.introScreenSection1.brideName = templateDetails.section1.brideName

                                    if (!newTemplateDetails.introScreenSection1.brideNameStyles)
                                        newTemplateDetails.introScreenSection1.brideNameStyles = templateDetails.section1.brideNameStyles

                                    if (!newTemplateDetails.introScreenSection1.childrenName)
                                        newTemplateDetails.introScreenSection1.childrenName = templateDetails.section1.childrenName

                                    if (!newTemplateDetails.introScreenSection1.childrenNameStyles)
                                        newTemplateDetails.introScreenSection1.childrenNameStyles = templateDetails.section1.childrenNameStyles

                                    if (!newTemplateDetails.introScreenSection1.dateStyles)
                                        newTemplateDetails.introScreenSection1.dateStyles = {'color': templateDetails.section2.dateStyles}

                                    if (!newTemplateDetails.introScreenSection1.envelopeColor)
                                        newTemplateDetails.introScreenSection1.envelopeColor = {'color': settings.introScreen.config.envelopeColor}

                                    if (!newTemplateDetails.introScreenSection1.envelopeTextStyle)
                                        newTemplateDetails.introScreenSection1.envelopeTextStyle = {'color': settings.introScreen.config.fontColor}
                                    if (!newTemplateDetails.introScreenSection1.envelopeText)
                                        newTemplateDetails.introScreenSection1.envelopeText = translateInviteTexts("Apasă pentru a deschide invitația", weddingData.language)


                                    if (!newTemplateDetails.introScreenSection2)
                                        newTemplateDetails.introScreenSection2 = {}

                                    if (!newTemplateDetails.introScreenSection2.text4)
                                        newTemplateDetails.introScreenSection2.text4 = templateDetails.section3.text4

                                    if (!newTemplateDetails.introScreenSection2.text4Styles)
                                        newTemplateDetails.introScreenSection2.text4Styles = templateDetails.section3.text4Styles

                                    if (!newTemplateDetails.introScreenSection2.showConfirmButton)
                                        newTemplateDetails.introScreenSection2.showConfirmButton = true

                                    if (!newTemplateDetails.introScreenSection2.confirmButtonColor)
                                        newTemplateDetails.introScreenSection2.confirmButtonColor = {'color': settings.introScreen.config.envelopeColor}

                                    if (!newTemplateDetails.introScreenSection2.showViewLocationButton)
                                        newTemplateDetails.introScreenSection2.showViewLocationButton = true
                                    if (!newTemplateDetails.introScreenSection2.viewLocationButtonColor)
                                        newTemplateDetails.introScreenSection2.viewLocationButtonColor = {'color': settings.introScreen.config.envelopeColor}

                                    if (!newTemplateDetails.introScreenSection2.showOpenInvitationButton)
                                        newTemplateDetails.introScreenSection2.showOpenInvitationButton = true
                                    if (!newTemplateDetails.introScreenSection2.openInvitationButtonColor)
                                        newTemplateDetails.introScreenSection2.openInvitationButtonColor = {'color': settings.introScreen.config.envelopeColor}
                                    if (!newTemplateDetails.introScreenSection2.confirmButtonIconColor)
                                        newTemplateDetails.introScreenSection2.confirmButtonIconColor = {'color': settings.introScreen.config.fontColor}
                                    if (!newTemplateDetails.introScreenSection2.viewLocationButtonIconColor)
                                        newTemplateDetails.introScreenSection2.viewLocationButtonIconColor = {'color': settings.introScreen.config.fontColor}
                                    if (!newTemplateDetails.introScreenSection2.openInvitationButtonIconColor)
                                        newTemplateDetails.introScreenSection2.openInvitationButtonIconColor = {'color': settings.introScreen.config.fontColor}
                                } else {
                                    newTemplateDetails.introScreenId = 0
                                    newTemplateDetails.introScreenConfiguration = 0
                                }
                                setTemplateDetails(newTemplateDetails)
                            }}/>
                    </div>
                </>
            :null
        }

        {
            templateDetails && templateDetails.id && templateDetails.introScreenId ?
            <>
            <IntroScreenTemplateRenderLazy
            templateDetails={templateDetails}
        weddingData={weddingData}
        mode={2}
        toggleModalIntro={toggleModalIntro}
        showResponseButton={true}
        showIntroScreen={true}
    />

    <div className="Section">
        <div className="Title">Resetare template la valorile inițiale
        </div>

        <div className="Options-Row">
            <div
                className={"active-option"}
                style={{width: 'unset'}}
                onClick={_ => setShowResetModal(true)}><Icon icon={"cog"}/> Resetare
            </div>

        </div>
    </div>


    <Modal classNames={{
        overlay: 'modal-overlay',
        modal: 'modal-no-top',
        closeButton: 'modal-close-button',
    }} focusTrapped={false} open={showModalSection1}
           onClose={() => toggleModalIntro('introScreenSection1')}>

        <div className={"invite-section-edit-modal"}>

            {
                (eventType === 'Nunta' || eventType === 'Cununie') &&
                <>
                    <CustomInput
                        value={templateDetails.introScreenSection1.groomName}
                        onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'groomName', value)}
                        placeholder={"Primul nume (mire/mireasă)"}
                        className={'text-style-input'}
                                    />

                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.groomNameStyles ? templateDetails.introScreenSection1.groomNameStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'groomNameStyles', value)}
                                        borderBottomRadius={true}
                                    />

                                    <div style={{marginTop: '5px', marginLeft: '8px'}}>
                                        <label>Separator</label><br/>
                                        <label>
                                            <input type={"checkbox"}
                                                   checked={!templateDetails.introScreenSection1.separator || templateDetails.introScreenSection1.separator === 0}
                                                   onChange={() => changeTemplateSectionValue('introScreenSection1', 'separator', 0)}/> &
                                        </label>
                                        <label style={{marginLeft: '20px'}}>
                                            <input type={"checkbox"}
                                                   checked={templateDetails.introScreenSection1.separator === 1}
                                                   onChange={() => changeTemplateSectionValue('introScreenSection1', 'separator', 1)}/> și
                                        </label>
                                    </div>
                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.separatorStyles ? templateDetails.introScreenSection1.separatorStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'separatorStyles', value)}
                                    />

                                    <CustomInput
                                        value={templateDetails.introScreenSection1.brideName}
                                        onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'brideName', value)}
                                        placeholder={"Al doilea nume (mire/mireasă)"}
                                        className={'text-style-input'}
                                    />

                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.brideNameStyles ? templateDetails.introScreenSection1.brideNameStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'brideNameStyles', value)}
                                        borderBottomRadius={true}
                                    />

                                    {
                                        templateDetails.section1.nuntaSiBotez ?
                                            <>
                                                <CustomInput
                                                    value={templateDetails.introScreenSection1.childrenName}
                                                    onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'childrenName', value)}
                                                    placeholder={"Nume copil"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.introScreenSection1.childrenNameStyles ? templateDetails.introScreenSection1.childrenNameStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'childrenNameStyles', value)}
                                                    borderBottomRadius={true}
                                                />
                                            </>
                                            : null
                                    }


                                </>
                            }

                            {
                                (eventType === 'Botez' || eventType === "Mot" || eventType === "Turta") &&
                                <>
                                    <CustomInput
                                        value={templateDetails.introScreenSection1.groomName}
                                        onChange={(value) => {
                                            changeTemplateSectionValue('introScreenSection1', 'groomName', value)
                                        }}
                                        placeholder={"Numele copilului"}
                                        className={'text-style-input'}
                                    />

                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.groomNameStyles ? templateDetails.introScreenSection1.groomNameStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'groomNameStyles', value)}
                                        borderBottomRadius={true}
                                    />

                                </>
                            }

                            {
                                eventType === 'Petrecere' &&
                                <>
                                    <CustomInput
                                        value={templateDetails.introScreenSection1.groomName}
                                        onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'groomName', value)}
                                        placeholder={"Titlul evenimentului"}
                                        className={'text-style-input'}
                                    />

                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.groomNameStyles ? templateDetails.introScreenSection1.groomNameStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'groomNameStyles', value)}
                                        borderBottomRadius={true}
                                    />

                                </>
                            }

                            {corporateWeddingEvents.includes(eventType) &&
                                <>
                                    <CustomInput
                                        value={templateDetails.introScreenSection1.groomName}
                                        onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'groomName', value)}
                                        placeholder={"Titlul evenimentului"}
                                        className={'text-style-input'}
                                    />
                                    <TextStyleComp
                                        styles={templateDetails.introScreenSection1.groomNameStyles ? templateDetails.introScreenSection1.groomNameStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'groomNameStyles', value)}
                                        borderBottomRadius={true}
                                    />
                                </>}

                            <div className={"input-title"} style={{marginTop: '8px'}}>Data evenimentului</div>
                            <DatePicker ranges={[]}
                                        container={() => document.getElementById("inv-sec-ed-mod")}
                                        format="DD-MM-YYYY"
                                        value={new Date(templateDetails.section2.date)}
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            backgroundColor: "#E5F7FE",
                                            //  marginBottom: '10px'
                                        }}
                                        placement="bottomStart"
                                        defaultOpen={false} oneTap
                                        disabled={true}
                                        appearance="subtle" placeholder="Data evenimentului"
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        cleanable={false}
                            /> <DateContainerStyleComp
                            options={['color']}
                            styles={templateDetails.introScreenSection1.dateStyles ? templateDetails.introScreenSection1.dateStyles : {}}
                            setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'dateStyles', value)}
                            borderBottomRadius={true}
                        />


                            <div className={"input-title"} style={{marginTop: '8px'}}>Culoarea plicului</div>
                            <TextStyleComp
                                options={['color']}
                                styles={templateDetails.introScreenSection1.envelopeColor ? templateDetails.introScreenSection1.envelopeColor : {}}
                                setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'envelopeColor', value)}
                                borderBottomRadius={true}
                            />
                            <>
                                <CustomInput
                                    value={templateDetails.introScreenSection1.envelopeText}
                                    onChange={(value) => changeTemplateSectionValue('introScreenSection1', 'envelopeText', value)}
                                    placeholder={"Textul de pe plic"}
                                    className={'text-style-input'}
                                />

                                <TextStyleComp
                                    styles={templateDetails.introScreenSection1.envelopeTextStyle ? templateDetails.introScreenSection1.envelopeTextStyle : {}}
                                    setStyles={(value) => changeTemplateSectionValue('introScreenSection1', 'envelopeTextStyle', value)}
                                    borderBottomRadius={true}
                                />

                            </>
                            <Button
                                onClick={() => {
                                    setTemplateDetails(templateDetails)

                                    let newWeddingData = {...weddingData}

                                    /*
                                    if (eventType === 'Nunta' || eventType === 'Cununie') {
                                        newWeddingData.prenumeMire = templateDetails.introScreenSection1.groomName
                                        newWeddingData.prenumeMireasa = templateDetails.introScreenSection1.brideName
                                        saveWeddingData(newWeddingData, locations)
                                    }
                                    if (eventType === 'Botez' || eventType === 'Mot' || eventType === 'Turta') {
                                        newWeddingData.prenumeMire = templateDetails.introScreenSection1.groomName
                                        saveWeddingData(newWeddingData, locations)
                                    }
                                    if (eventType === 'Petrecere') {
                                        newWeddingData.prenumeMire = templateDetails.introScreenSection1.groomName
                                        saveWeddingData(newWeddingData, locations)
                                    }
                                    if (corporateWeddingEvents.includes(eventType)) {
                                        newWeddingData.prenumeMire = templateDetails.introScreenSection1.groomName
                                        saveWeddingData(newWeddingData, locations)
                                    }*/


                                    toggleModalIntro('introScreenSection1')
                                }}
                                color="green"
                                style={{
                                    backgroundColor: "#F26157",
                                    borderRadius: "20px",
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "10px"
                                }}>Previzualizează</Button>
                        </div>
                    </Modal>

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }} focusTrapped={false} open={showModalSection2} onClose={() => {
                        toggleModalIntro('introScreenSection2')
                    }}>
                        <div className={"invite-section-edit-modal"} id={"inv-sec-ed-mod"}>

                            <CustomInput
                                value={templateDetails.introScreenSection2.text4}
                                onChange={(value) => changeTemplateSectionValue('introScreenSection2', 'text4', value)}
                                placeholder={"Frază de încheiere"}
                                componentClass={"textarea"}
                                className={'text-style-input'}
                            />

                            <TextStyleComp
                                styles={templateDetails.introScreenSection2.text4Styles ? templateDetails.introScreenSection2.text4Styles : {}}
                                setStyles={(value) => changeTemplateSectionValue('introScreenSection2', 'text4Styles', value)}
                                borderBottomRadius={true}
                            />

                            <div className={"input-title"} style={{marginTop: '8px'}}>
                                Afișează butonul Răspunde
                            </div>

                            <Toggle className={"green"}
                                    style={{
                                        width: "150px",
                                        alignSelf: "center",
                                        marginBottom: "8px"
                                    }}
                                    checked={templateDetails.introScreenSection2.showConfirmButton}
                                    onChange={status => {
                                        changeTemplateSectionValue('introScreenSection2', 'showConfirmButton', status)
                                    }} checkedChildren="Activată"
                                    unCheckedChildren="Dezactivată"/>
                            {
                                templateDetails.introScreenSection2.showConfirmButton && <>
                                    <IntroButtonStyleComp
                                        buttonFontStyles={templateDetails.introScreenSection2.confirmButtonFontStyles}
                                        setButtonFontStyles={(value) => changeTemplateSectionValue('introScreenSection2', 'confirmButtonFontStyles', value)}
                                        buttonColor={templateDetails.introScreenSection2.confirmButtonColor}
                                        setButtonColor={(value) => changeTemplateSectionValue('introScreenSection2', 'confirmButtonColor', value)}
                                        buttonIconColor={templateDetails.introScreenSection2.confirmButtonIconColor}
                                        setButtonIconColor={(value) => changeTemplateSectionValue('introScreenSection2', 'confirmButtonIconColor', value)}
                                    />
                                </>
                            }

                            <div className={"input-title"} style={{marginTop: '8px'}}>
                                Afișează butonul vezi locație
                            </div>

                            <Toggle className={"green"}
                                    style={{
                                        width: "150px",
                                        alignSelf: "center",
                                        marginBottom: "8px"
                                    }}
                                    checked={templateDetails.introScreenSection2.showViewLocationButton}
                                    onChange={status => {
                                        changeTemplateSectionValue('introScreenSection2', 'showViewLocationButton', status)
                                    }} checkedChildren="Activată"
                                    unCheckedChildren="Dezactivată"/>
                            {
                                templateDetails.introScreenSection2.showViewLocationButton &&
                                <>
                                    <IntroButtonStyleComp
                                        buttonFontStyles={templateDetails.introScreenSection2.viewLocationButtonFontStyles}
                                        setButtonFontStyles={(value) => changeTemplateSectionValue('introScreenSection2', 'viewLocationButtonFontStyles', value)}
                                        buttonColor={templateDetails.introScreenSection2.viewLocationButtonColor}
                                        setButtonColor={(value) => changeTemplateSectionValue('introScreenSection2', 'viewLocationButtonColor', value)}
                                        buttonIconColor={templateDetails.introScreenSection2.viewLocationButtonIconColor}
                                        setButtonIconColor={(value) => changeTemplateSectionValue('introScreenSection2', 'viewLocationButtonIconColor', value)}
                                    />
                                </>
                            }

                            <div className={"input-title"} style={{marginTop: '8px'}}>
                                Afișează butonul deschide invitația
                            </div>

                            <Toggle className={"green"}
                                    style={{
                                        width: "150px",
                                        alignSelf: "center",
                                        marginBottom: "8px"
                                    }}
                                    checked={templateDetails.introScreenSection2.showOpenInvitationButton}
                                    onChange={status => {
                                        changeTemplateSectionValue('introScreenSection2', 'showOpenInvitationButton', status)
                                    }} checkedChildren="Activată"
                                    unCheckedChildren="Dezactivată"/>

                            {
                                templateDetails.introScreenSection2.showOpenInvitationButton &&
                                <>
                                    <IntroButtonStyleComp
                                        buttonFontStyles={templateDetails.introScreenSection2.openInvitationButtonFontStyles}
                                        setButtonFontStyles={(value) => changeTemplateSectionValue('introScreenSection2', 'openInvitationButtonFontStyles', value)}
                                        buttonColor={templateDetails.introScreenSection2.openInvitationButtonColor}
                                        setButtonColor={(value) => changeTemplateSectionValue('introScreenSection2', 'openInvitationButtonColor', value)}
                                        buttonIconColor={templateDetails.introScreenSection2.openInvitationButtonIconColor}
                                        setButtonIconColor={(value) => changeTemplateSectionValue('introScreenSection2', 'openInvitationButtonIconColor', value)}
                                    />

                                </>
                            }
                            <Button
                                onClick={() => {
                                    setTemplateDetails(templateDetails)
                                    toggleModalIntro('introScreenSection2')
                                }}
                                color="green"
                                style={{
                                    backgroundColor: "#F26157",
                                    borderRadius: "20px",
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "10px"
                                }}>Previzualizează</Button>
                        </div>
                    </Modal>

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }} focusTrapped={false} open={showResetModal} onClose={() => {
                        setShowResetModal(false)
                    }}>

                        <div className={"invite-section-edit-modal"}>

                            <p>Vă rugăm alegeți informațiile pe care doriți să le resetați la
                                valorile
                                inițiale.</p>

                            <Button
                                onClick={() => {
                                    setTemplateDetails(resetTemplateIntroScreenStyles(templateDetails))
                                    setShowResetModal(false)
                                }}
                                color="green"
                                style={{
                                    borderRadius: "20px",
                                    width: "100%",
                                    marginTop: "20px",
                                }}>Resetează stilurile</Button>

                            <Button
                                onClick={() => {
                                    setTemplateDetails({
                                        eventType: templateDetails.eventType,
                                        id: templateDetails.id,
                                        ...templateDetails,
                                        ...getDefaultTemplateValues({data: weddingData}, templateDetails.id, 'ro', ['intro'])
                                    })
                                    setShowResetModal(false)
                                }}
                                color="blue"
                                style={{
                                    borderRadius: "20px",
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "10px"
                                }}>Resetează stilurile și textele</Button>
                        </div>
                    </Modal>

                </>
                :
                null
        }

    </div>)
}
