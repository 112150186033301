import React, {lazy, Suspense, useState} from 'react'
import './IntroAnimationRenderComp.scss'

const IntroAnimation1 = lazy(() => import("../../introAnimations/IntroAnimation1/IntroAnimation1"))
const IntroAnimation2 = lazy(() => import("../../introAnimations/introAnimation2/IntroAnimation2"))

export default function IntroAnimationRenderComp(
    {
        show,
        templateDetails,
        weddingData,
        setShowInvite,
        autoPlayIntroAnimation,
        animationState,
        setAnimationState,
        setAutoPlayIntroAnimation,
        showIntroAnimation,
        showInvite
    }
) {
    const [autoPlay, setAutoPlay] = useState(false)


    const renderIntroAnimation = () => {
        switch (templateDetails.introAnimationId) {
            case 1:
                return <IntroAnimation1
                    templateDetails={templateDetails}
                    weddingData={weddingData}
                    setShowInvite={setShowInvite}
                    autoPlay={autoPlay || autoPlayIntroAnimation}
                    setAutoPlay={(value) => {
                        setAutoPlay(value)
                        setAutoPlayIntroAnimation(value)
                    }}
                    show={show}
                    showInvite={showInvite}
                    showIntroAnimation={showIntroAnimation}
                    animationState={animationState}
                    setAnimationState={setAnimationState}
                />
            case 2:
                return <IntroAnimation2
                    templateDetails={templateDetails}
                    weddingData={weddingData}
                    autoPlay={autoPlay || autoPlayIntroAnimation}
                    setAutoPlay={(value) => {
                        setAutoPlay(value)
                        setAutoPlayIntroAnimation(value)
                    }}
                    show={show}
                    showInvite={showInvite}
                    setShowInvite={setShowInvite}
                    showIntroAnimation={showIntroAnimation}
                    animationState={animationState}
                    setAnimationState={setAnimationState}
                    mode={1}
                />

            default:
                return null

        }
    }

    return <Suspense fallback={<div style={{padding: "30px 0"}}></div>}>
        <div className={"intro-animation-render-comp"} style={
            showInvite ? {zIndex: 0} :
                show || showIntroAnimation ? {
                    position: 'absolute',
                    left: 0, top: 0,
                    zIndex: 100, width: '100vw', minHeight: '100dvh', height: '100dvh', overflow:'hidden'
                } : {
                    position: 'absolute',
                    left: '-200vw',
                    top: '-200vh'
                }}>
            <div className={"iarc-inner"}>
                {renderIntroAnimation()}
            </div>
        </div>
    </Suspense>

}
