import React from 'react'
import './ConfigurationFields.scss'
import {useIntl} from "react-intl";
import {getIntroAnimationConfigValues} from "../../../../../InviteTemplatesFunctions";
import {SelectPicker} from "rsuite";

export default function ConfigurationFields(
    {
        introAnimationId,
        configuration,
        setConfiguration,
        hideAdditionalOptions = false,
        containerStyles = {}
    }
) {
    const intl = useIntl();
    const introAnimationConfigValues = getIntroAnimationConfigValues(introAnimationId).configuration

    const populateDefaultValues = (configurationItem) => {
        if(configuration[configurationItem.title]) {
            return
        }

        let newConfiguration = {...configuration}
        newConfiguration[configurationItem.title] = configurationItem.value
        setConfiguration(newConfiguration)
    }

    const renderConfigurationItem = (configurationItem) => {

        //Check if item has condition
        if(configurationItem.conditionFieldTitle) {
            if(configuration[configurationItem.conditionFieldTitle] !== configurationItem.conditionFieldValue) {
                return null
            }
        }

        if(configurationItem.conditionFieldTitle1) {
            if(configuration[configurationItem.conditionFieldTitle1] !== configurationItem.conditionFieldValue1) {
                return null
            }
        }

        populateDefaultValues(configurationItem)

        if((configurationItem.conditionFieldTitle || configurationItem.conditionFieldTitle1 ) && hideAdditionalOptions) {
            return null
        }

        switch (configurationItem.type) {
            case 'enumeration':
                return <div className={"config-item-row"}>
                    <div className={"config-item-title"}>{intl.formatMessage({id: 'intro_animation.config.'+configurationItem.titleIntl})}</div>
                    <SelectPicker
                        searchable={false}
                        data={configurationItem.values.map(item => {return  {value: item.value, label: intl.formatMessage({id: 'intro_animation.config.'+item.label})}})}
                        appearance="default"
                        onChange={(value) => {
                            let newConfiguration = {...configuration}
                            newConfiguration[configurationItem.title] = value
                            setConfiguration(newConfiguration)
                        }}
                        value={ configuration && configuration[configurationItem.title] ? configuration[configurationItem.title] : ''}
                        placeholder="Fără"
                        cleanable={!!configurationItem.canBeEmpty}

                    />
                </div>
            case 'color':
                return <div className={"config-item-row"}>
                    <div className={"config-item-title"}>{intl.formatMessage({id: 'intro_animation.config.'+configurationItem.titleIntl})}</div>

                    <input
                        className={"config-item-color-field"}
                        value={configuration && configuration[configurationItem.title] ? configuration[configurationItem.title] : ''}
                        type={"color"}
                        onChange={(e) => {
                            let newConfiguration = {...configuration}
                            newConfiguration[configurationItem.title] = e.target.value
                            setConfiguration(newConfiguration)
                        }}/>
                </div>


            default:
                return <></>

        }
    }

    return (<div className={"iacc-cont"} style={containerStyles}>
        {introAnimationConfigValues.map((item,i) => (<div key={i}>{renderConfigurationItem(item)}</div>))}
    </div>)
}
