export const introAnimations = [
    {
        id: 1,
        title: 'Animație simplă',
        configuration: [
            {
                title: 'optionalAnimation1',
                titleIntl: 'optional_animation',
                type: 'enumeration',
                value: 'birds',
                values: [
                    {
                        value: 'none',
                        label: 'none'
                    },
                    {
                        value: 'birds',
                        label: 'birds_animation'
                    },
                    {
                        value: 'confetti',
                        label: 'confetti_animation'
                    },
                    {
                        value: 'autumn_leaves',
                        label: 'autumn_leaves_animation'
                    },
                    {
                        value: 'rose_petals',
                        label: 'rose_petals_animation'
                    },
                    {
                        value: 'balloons',
                        label: 'balloons_animation'
                    },
                    {
                        value: 'fireworks',
                        label: 'fireworks_animation'
                    }
                    ],
            },
            {
                title: 'birdsColor',
                titleIntl: 'birds_color',
                type: 'color',
                value: '#333333',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'birds'
            },
            {
                title: 'autumnLeavesColor',
                titleIntl: 'leaves_color',
                type: 'color',
                value: '#9b5300',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'autumn_leaves'
            },
            {
                title: 'balloonsType',
                titleIntl: 'balloons_type',
                type: 'enumeration',
                value: 'normal',
                values: [
                    {
                        value: 'normal',
                        label: 'balloons_type_normal'
                    },
                    {
                        value: 'heart',
                        label: 'balloons_type_heart'
                    },
                    {
                        value: 'normal_heart',
                        label: 'balloons_type_normal_heart'
                    }
                ],
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons'
            },
            {
                title: 'balloonsColorsType',
                titleIntl: 'balloons_colors_type',
                type: 'enumeration',
                value: 'multicolor',
                values: [
                    {
                        value: 'single_color',
                        label: 'single_color'
                    },
                    {
                        value: 'multicolor',
                        label: 'multicolor'
                    },
                    {
                        value: 'random_colors',
                        label: 'random_colors'
                    }
                    ],
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons'
            },
            {
                title: 'balloonsColor',
                titleIntl: 'balloons_color',
                type: 'color',
                value: '#9b5300',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'single_color'
            },
            {
                title: 'balloonsColor1',
                titleIntl: 'balloons_color_1',
                type: 'color',
                value: '#7637AF',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor2',
                titleIntl: 'balloons_color_2',
                type: 'color',
                value: '#F1D136',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor3',
                titleIntl: 'balloons_color_3',
                type: 'color',
                value: '#009966',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor4',
                titleIntl: 'balloons_color_4',
                type: 'color',
                value: '#02A7D1',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor5',
                titleIntl: 'balloons_color_5',
                type: 'color',
                value: '#F6B21A',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
        ],
    },
    {
        id: 2,
        title: 'Animație de tip video',
        configuration: [
            {
                title: 'screens',
                value: [
                    {
                        id: 1,
                        template: 1,
                        content: {}
                    }
                ]
            }
        ]
    }
]



export const introAnimation2ScreenTemplatesConfig = [
    {
        id: 1,
        configuration: [
            {
                title: 'transition',
                titleIntl: 'transition',
                type: 'enumeration',
                value: 'default',
                values: [
                    {
                        value: 'default',
                        label: 'transition.fade_in_out'
                    },
                    {
                        value: 'book_page',
                        label: 'transition.book_page'
                    },
                    {
                        value: 'tiles',
                        label: 'transition.tiles'
                    },
                    {
                        value: 'circle_tiles',
                        label: 'transition.circle_tiles'
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        configuration: [
            {
                title: 'transition',
                titleIntl: 'transition',
                type: 'enumeration',
                value: 'default',
                values: [
                    {
                        value: 'default',
                        label: 'transition.fade_in_out'
                    },
                    {
                        value: 'book_page',
                        label: 'transition.book_page'
                    },
                    {
                        value: 'tiles',
                        label: 'transition.tiles'
                    },
                    {
                        value: 'circle_tiles',
                        label: 'transition.circle_tiles'
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        configuration: [
            {
                title: 'transition',
                titleIntl: 'transition',
                type: 'enumeration',
                value: 'default',
                values: [
                    {
                        value: 'default',
                        label: 'transition.fade_in_out'
                    },
                    {
                        value: 'book_page',
                        label: 'transition.book_page'
                    },
                    {
                        value: 'tiles',
                        label: 'transition.tiles'
                    },
                    {
                        value: 'circle_tiles',
                        label: 'transition.circle_tiles'
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        configuration: [
            {
                title: 'transition',
                titleIntl: 'transition',
                type: 'enumeration',
                value: 'default',
                values: [
                    {
                        value: 'default',
                        label: 'transition.fade_in_out'
                    },
                    {
                        value: 'book_page',
                        label: 'transition.book_page'
                    },
                    {
                        value: 'tiles',
                        label: 'transition.tiles'
                    },
                    {
                        value: 'circle_tiles',
                        label: 'transition.circle_tiles'
                    }
                ]
            }
        ]
    },
    {
        id: 5,
        configuration: [
            {
                title: 'title_animation',
                titleIntl: 'title_animation',
                type: 'enumeration',
                value: 'waves',
                values: [
                    {
                        value: 'waves',
                        label: 'title_animation.waves'
                    },
                    {
                        value: 'particles',
                        label: 'title_animation.particles'
                    }
                ]
            },
            {
                title: 'transition',
                titleIntl: 'transition',
                type: 'enumeration',
                value: 'default',
                values: [
                    {
                        value: 'default',
                        label: 'transition.fade_in_out'
                    },
                    {
                        value: 'book_page',
                        label: 'transition.book_page'
                    },
                    {
                        value: 'tiles',
                        label: 'transition.tiles'
                    },
                    {
                        value: 'circle_tiles',
                        label: 'transition.circle_tiles'
                    }
                ]
            }
        ]
    }
]

export const introAnimation2PredefinedSets = {
    Nunta: [
        {
            id: 1,
            title: 'Model 1',
            screens: [
                {
                    id: 1,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section1',
                                parameter: 'text1'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            secondary_object: 'titleStyles',
                            parameter: 'fontSize',
                            value_object: {
                                type: 'plain',
                                value: 'style-font-size-smaller'
                            }
                        }

                    ]
                },
                {
                    id: 2,
                    template: 5,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'event_title_full'
                            }
                        }

                    ]
                },
                {
                    id: 3,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section1',
                                parameter: 'text2'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            secondary_object: 'titleStyles',
                            parameter: 'fontSize',
                            value_object: {
                                type: 'plain',
                                value: 'style-font-size-smaller'
                            }
                        }
                    ]
                },
                {
                    id: 4,
                    template: 2,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        }
                    ]
                },
                {
                    id: 5,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'parents_text_full'

                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'godparents_text_full'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        }
                    ]
                },
                {
                    id: 6,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section3',
                                parameter: 'text4'
                            }
                        }
                    ]
                },
            ]
        },
        {
            id: 2,
            title: 'Model 2',
            screens: [
                {
                    id: 1,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section1',
                                parameter: 'text1'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            secondary_object: 'titleStyles',
                            parameter: 'fontSize',
                            value_object: {
                                type: 'plain',
                                value: 'style-font-size-smaller'
                            }
                        }

                    ]
                },
                {
                    id: 2,
                    template: 5,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'event_title_full'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'options',
                            value_object: {
                                type: 'plain',
                                value: {
                                    "transition": "default",
                                    "title_animation": "particles"
                                }
                            }
                        }

                    ]
                },
                {
                    id: 3,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section1',
                                parameter: 'text2'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            secondary_object: 'titleStyles',
                            parameter: 'fontSize',
                            value_object: {
                                type: 'plain',
                                value: 'style-font-size-smaller'
                            }
                        }
                    ]
                },
                {
                    id: 4,
                    template: 2,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        }
                    ]
                },
                {
                    id: 5,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'parents_text_full'

                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'godparents_text_full'
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        }
                    ]
                },
                {
                    id: 6,
                    template: 1,
                    variables: [
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text1',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'text2',
                            value_object: {
                                type: 'plain',
                                value: ''
                            }
                        },
                        {
                            type: 'replace',
                            main_object: 'data',
                            parameter: 'title',
                            value_object: {
                                type: 'variable',
                                main_object: 'templateDetails',
                                secondary_object: 'section3',
                                parameter: 'text4'
                            }
                        }
                    ]
                },
            ]
        }
    ],
    Cununie: [

    ],
    Botez: [

    ],
    Mot: [

    ],
    Turta: [

    ],
    Petrecere: [

    ],
    Corporate: [

    ]
}