import React, {useState, useEffect} from 'react';
import axios from "axios";
import {PlayAudio} from "../../../components/Helper";
import {Button, Input, Message} from "rsuite";
import {BsFillMicFill} from "react-icons/bs";
import './style.scss';
import {toast} from "react-toastify";
import MicRecorder from "mic-recorder-to-mp3";
import PlayAudioButton from "./PlayAudioButton";

const Mp3Recorder = new MicRecorder({bitRate: 128});

export default function AddAudio({
                                     audioType,
                                     setAudioType,
                                     serverAudioUrl,
                                     deleteServerAudio,
                                     setAudioBlobData,
                                     allowAudioRequest,
                                     customAudioLink,
                                     customAudioSecond,
                                     setCustomAudioLink,
                                     setCustomAudioSecond,
                                     setDeleteServerAudio,
                                     songSelected,
                                     setSongSelected,
                                     initialWeddingData
                                 }) {

    const [loading, setLoading] = useState(true)
    const [songs, setSongs] = useState([])
    const [timer, setTimer] = useState(0)
    const [timerStarted, setTimerStarted] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [blobURL, setBlobURL] = useState('')
    const [currentSong, setCurrentSong] = useState(null)
    const isBlocked = false

    const start = () => {
        if (isBlocked) {
            toast.info("Microfonul este blocat, acordati permisiune pentru a-l folosii.")
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    setBlobURL('')
                    setIsRecording(true)
                    setAudioBlobData(null)
                    setTimer(0)
                    setTimerStarted(true)
                }).catch((e) => {
                toast.warn("Microfonul nu poate fi detectat")
            });
        }
    };

    const stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const temp = URL.createObjectURL(blob)
                setBlobURL(temp)
                setIsRecording(false)
                setAudioBlobData(blob)
                setTimer(0)
                setTimerStarted(false)
            }).catch((e) => console.log(e));
    };

    useEffect(() => {
        getSongs()
    }, [])


    const getSongs = async () => {

        if(localStorage.getItem('jwt')) {
            await axios.get(process.env.REACT_APP_DOMAIN + 'invites/get-songs', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            })
                .then(response => {
                    if (response && response.data) {
                        setSongs(response.data.songs)
                        setLoading(false)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        } else {
            await axios.get(process.env.REACT_APP_DOMAIN + 'invites/get-songs', {
                headers: {

                }
            })
                .then(response => {
                    if (response && response.data) {
                        setSongs(response.data.songs)
                        setLoading(false)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }

    }

    return (<div>
            {serverAudioUrl &&
                <>
                    <h4 style={{textAlign: "center", marginLeft: "0px"}}> Audio curent </h4>
                    <div className="Upload-Audio" style={{marginBottom: "20px"}}>
                        <PlayAudio
                            link={serverAudioUrl}/>

                        <div className="Section">
                            <div className="Title">Doriți să schimbați melodia?</div>

                            <div className="Options-Row">
                                <div
                                    className={deleteServerAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => setDeleteServerAudio(true)}
                                    style={{marginRight: "30px"}}>Da
                                </div>
                                <div
                                    className={!deleteServerAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => setDeleteServerAudio(false)}>Nu
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            {(deleteServerAudio || !serverAudioUrl) && <div className={"add-audio-row"}>
                <div className={"add-audio-col"}>
                    <div className={"add-audio-card " + (audioType === 'song' ? 'active' : '')}
                         onClick={() => setAudioType('song')}>
                        Selectează o melodie
                    </div>
                </div>
                <div className={"add-audio-col"}>
                    <div className={"add-audio-card " + (audioType === 'message' ? 'active' : '')}
                         onClick={() => setAudioType('message')}>
                        Inregistreaza mesaj
                    </div>
                </div>
                <div className={"add-audio-col"}>
                    <div className={"add-audio-card " + (audioType === 'link' ? 'active' : '')}
                         onClick={() => setAudioType('link')}>
                        Trimiteți-ne link-ul melodiei
                    </div>
                </div>
            </div>}
            {
                (deleteServerAudio || !serverAudioUrl) && audioType === 'song' &&
                <>
                    {
                        songSelected && <div style={{marginBottom: '12px'}}>
                            Melodia selectată: {songSelected.name}
                        </div>
                    }
                    <div className={"songs-container"}>
                        {
                            !loading && songs && songs.map((song) => (
                                <div key={song.name}
                                     className={'song-container ' + (songSelected && song.id === songSelected.id ? 'song-selected' : '')}>
                                    <PlayAudioButton song={song} setCurrentSong={setCurrentSong}
                                                     play={currentSong === song.id}
                                    />

                                    {((songSelected && song.id !== songSelected.id) || !songSelected) &&
                                        <div className={"choose-song"}>
                                            <button

                                                onClick={() => {
                                                    setSongSelected(song)
                                                    setCurrentSong(null)
                                                }}>
                                                Alege
                                            </button>
                                        </div>}
                                </div>
                            ))
                        }
                    </div>

                </>
            }
            {(deleteServerAudio || !serverAudioUrl) && audioType === 'message' &&
                <>
                    <div style={{textAlign: "center", marginLeft: "0px"}}> Inregistreaza mesaj
                        audio {serverAudioUrl ? "(va inlocuii mesajul audio anterior)" : ""} </div>
                    <div className="Upload-Audio">
                        {blobURL && <PlayAudio link={blobURL}/>}

                        <div className="Buttons">

                            {
                                timerStarted ?
                                    <div>
                                        {timer} secunde
                                        {console.log(setTimeout(() => {
                                            setTimer(timer + 1)
                                        }, 1000))}
                                    </div>
                                    : null
                            }

                            {!isRecording &&
                                <Button size="lg" appearance="primary" style={{marginRight: "10px"}}
                                        onClick={start}
                                        disabled={isRecording}>Inregistreaza</Button>}

                            {isRecording &&
                                <BsFillMicFill color={"red"}
                                               style={{margin: "0px 10px", alignSelf: "center"}}/>}

                            <Button size="lg" color="red" style={{marginLeft: "10px"}}
                                    onClick={stop}
                                    disabled={!isRecording}>Stop</Button>
                        </div>
                    </div>
                </>}


            {
                (deleteServerAudio || !serverAudioUrl) && audioType === 'link' &&
                <>
                    {
                        !initialWeddingData.packages ||
                        (initialWeddingData.packages && initialWeddingData.packages['wedding_participants']
                            && initialWeddingData.packages['wedding_participants'].package === 0
                        ) ?
                            <div>
                                <Message type="warning" style={{marginBottom: '12px'}}
                                         description={"Această funcționalitate este disponibilă doar după achiziționarea unui pachet!"}/>
                            </div>
                            :
                            <>
                                <div>Trimiteți-ne link-ul melodiei și minutul/secunda de la care să
                                    înceapă
                                </div>
                                {
                                    allowAudioRequest ?
                                        <>
                                            <Input value={customAudioLink}
                                                   onChange={value => setCustomAudioLink(value)}
                                                   placeholder={"Link de YouTube"}
                                                   style={{marginTop: '10px'}}
                                                   type={"text"}/>

                                            <Input value={customAudioSecond}
                                                   onChange={value => setCustomAudioSecond(value)}
                                                   placeholder={"ex: 00:32"}
                                                   style={{marginTop: '10px', marginBottom: '10px'}}
                                                   type={"text"}/>
                                            <div>După ce apăsați butonul "Salvează" vă rugăm să
                                                așteptați maxim
                                                48
                                                de ore până la
                                                apariția
                                                melodiei pe invitație.
                                            </div>
                                        </>
                                        :
                                        <p style={{color: '#FF0000'}}>Ați trimis deja o solicitare!
                                            Vă rugăm să
                                            așteptați 48 de ore pentru procesare.</p>
                                }
                            </>

                    }

                </>
            }

        </div>
    )
}

