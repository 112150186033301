import React, {lazy, Suspense, useState} from 'react'
import './IntroAnimationConfigComp.scss'
import {
    getIntroAnimationConfigValues,
    getTemplateConfigValues
} from "../../../InviteTemplatesFunctions";
import {Button, Icon, Message, Toggle} from "rsuite";
import {useIntl} from "react-intl";
import ConfigurationFields from "./components/ConfigurationFields/ConfigurationFields";
import Animation_Icon from "../../../../organizer/assets/icons/invite-animation.svg"
import SVG_Walkthrough_Arrow from "../../../../assets/icons/walkthrough_arrow.svg";
import {introAnimations} from "../introAnimations";
import Loader from "../../../Loader";
import Modal from "react-responsive-modal";
import {BackAndConfirmButtons, ConfirmButton} from "../../../Helper";
import {getLanguageFlag, getLanguageName} from "../../../../functions";
const IntroAnimation2Configurator = lazy(() =>  import("../configurators/IntroAnimation2Configurator/IntroAnimation2Configurator"));

export default function IntroAnimationConfigComp(
    {
        templateDetails,
        setTemplateDetails,
        initialWeddingData,
        templateConfigValues
    }
) {

    const intl = useIntl();
    const availableAnimations = getTemplateConfigValues(templateDetails.id).introAnimations

    const toggleStyle = {
        margin: "10px 0px",
        textAlign: 'center',
        maxWidth: "500px",
        alignSelf: 'center'
    }

    const [showAnimationsSelector, setShowAnimationsSelector] = useState(false)
    const [showChangeTypeWarningModal, setShowChangeTypeWarningModal] = useState(false)


    const selectNewAnimationType = (id) => {
        let newTemplateDetails = {...templateDetails}
        newTemplateDetails.introAnimationId = id

        //Define default configuration values
        let introAnimationConfiguration = {}
        let configurationValues = getIntroAnimationConfigValues(id).configuration
        if(configurationValues) {
            configurationValues.forEach(item => {
                if (templateConfigValues && templateConfigValues.introAnimationsConfig &&
                    templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId] && templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId][item.title])
                    introAnimationConfiguration[item.title] = templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId][item.title]
                else
                    introAnimationConfiguration[item.title] = item.value
            })
            newTemplateDetails.introAnimationConfiguration = introAnimationConfiguration
        }
        setTemplateDetails(newTemplateDetails)


    }


    return (<div className={"intro-animation-config-comp"}>

            {showAnimationsSelector ?
                <>
                    <h5 className={"invite-type-title"}>Alegeți un tip de animație</h5>

                    <div className={"chooseTemplate"}>
                        {introAnimations.map((item, i) => {
                            if(availableAnimations.includes(item.id)) return (
                                <div
                                    key={i}
                                    className={"item with-title"}
                                    onClick={() => {
                                        selectNewAnimationType(item.id)
                                        setShowAnimationsSelector(false)
                                    }}
                                >
                                    <div className={"animation-type-image-cont"}>
                                    <img src={templateConfigValues.preview} alt={""} />

                                        <div className={"type-icon-cont"}>
                                        {i === 0 ?
                                            <Icon icon="play"/>
                                        :null}
                                        {i === 1 ?
                                            <Icon icon="video-camera"/>
                                            :null}
                                        </div>
                                    </div>
                                    {item.title}

                                    {i === 0 ?
                                        <div className={"type-description"}>
                                            Prezintă invitația într-un mod dinamic și flexibil, permițând ajustări ușoare.
                                        </div>
                                        :null}

                                    {i === 1 ?
                                        <div className={"type-description"}>
                                            Animație detaliată ce simulează tranziția între ecrane complet personalizabile.
                                        </div>
                                        :null}

                                </div>
                            )
                        })}
                    </div>
                </>
                :
                availableAnimations.length > 0 ?
                    <div style={{marginBottom: '1rem'}}>
                    {!templateDetails.introAnimationId ?
                        <div className={"section-info-cont"}>

                            <div className={"header-cont"}>
                                <div className={"title-col"}>
                                    <div className={"title-1"}>
                                        Transmite un gram de
                                    </div>
                                    <div className={"title-2"}>
                                        EMOȚIE
                                    </div>
                                </div>
                                <div className={"icon-col"}>
                                    <img src={Animation_Icon} alt=""/>
                                </div>
                            </div>
                            <div className={"description"}>
                                Oferă o experiență captivantă cu invitațiile animate!
                            </div>
                            <div className={"description"}>
                                Animația respectă stilul modelulul de invitație ales și îți permite să personalizezi
                                culorile și animațiile secundare (păsări, confetti, frunze de toamnă, baloane etc.).
                            </div>
                            <div className={"description"}>
                                Invitația animată oferă o experiență cu adevărat minunată atunci când este însoțită de o
                                melodie.
                                Poți alege din lista noastră de melodii sau ne poți trimite un link de YouTube.
                            </div>

                            <div className={"footer-cont"}>
                                <div className={"cta-col"}>
                                    <div className={"text-col"}>
                                        Activează opțiunea
                                    </div>
                                    <div className={"img-col"}>
                                        <img src={SVG_Walkthrough_Arrow} alt=""/>
                                    </div>
                                </div>
                                <div className={"right-col"}></div>
                            </div>


                        </div>
                        : <>
                            {!(initialWeddingData && initialWeddingData.packages &&
                                    initialWeddingData.packages['wedding_participants'] && initialWeddingData.packages['wedding_participants'].details &&
                                    initialWeddingData.packages['wedding_participants'].details.addons &&
                                    initialWeddingData.packages['wedding_participants'].details.addons.find(item => item.type === "invitation_animation")) &&
                                <Message type="warning" style={{marginBottom: '12px'}}
                                         description={"Puteți testa această funcționalitate în modul de previzualizare dar în cadrul invitației va apărea doar după achiziționarea unui pachet cu animație!"}/>
                            }</>}


                    {intl.formatMessage({id: 'intro_animation.config.enable_question'})}
                    <br/>
                    <Toggle
                        style={toggleStyle}
                        checked={!!(templateDetails.introAnimationId && templateDetails.introAnimationId > 0)}
                        size="lg"
                        checkedChildren={intl.formatMessage({id: 'general.yes'})}
                        unCheckedChildren={intl.formatMessage({id: 'general.no'})}
                        onChange={(value) => {

                            if (value) {
                                //For single animation available
                                if(availableAnimations[0] && availableAnimations.length === 1) {
                                   selectNewAnimationType(availableAnimations[0])
                                } else {
                                    setShowAnimationsSelector(true)
                                }
                            } else {
                                let newTemplateDetails = {...templateDetails}
                                newTemplateDetails.introAnimationId = 0
                                setTemplateDetails(newTemplateDetails)
                            }
                        }}/>

                        {!(!!(templateDetails.introAnimationId && templateDetails.introAnimationId > 0)) ||
                            (availableAnimations && availableAnimations[0] && availableAnimations.length === 1) ?
                            null
                            :
                            <div className={"edit-invite-main-buttons-cont"}>
                            <div className={"eim-button yellow"}
                                 onClick={() => setShowChangeTypeWarningModal(true)}>
                                Schimbă tipul animației
                            </div></div>}


                        {templateDetails.introAnimationId ?

                            templateDetails.introAnimationId === 1 ?
                                <div>
                                    {/* introAnimation template configuration */}
                                    <ConfigurationFields
                                        introAnimationId={templateDetails.introAnimationId}
                                        configuration={templateDetails.introAnimationConfiguration}
                                        setConfiguration={(value) => {
                                            let newTemplateDetails = {...templateDetails}
                                            newTemplateDetails.introAnimationConfiguration = value
                                            setTemplateDetails(newTemplateDetails)
                                        }}
                                    />

                                    <div className="alert alert-warning">
                                        <Message type="info"
                                                 description={"Pentru o experiență mai captivantă îți recomandăm să selectezi o melodie pentru invitația ta din secțiunea 'Opțiuni'"}/>
                                    </div>
                                </div>
                                : templateDetails.introAnimationId === 2 ?
                                    <Suspense fallback={<Loader/>}>
                                        <IntroAnimation2Configurator
                                            configuration={templateDetails.introAnimationConfiguration}
                                            setConfiguration={(value) => {
                                                let newTemplateDetails = {...templateDetails}
                                                newTemplateDetails.introAnimationConfiguration = value
                                                setTemplateDetails(newTemplateDetails)
                                            }}
                                            weddingData={initialWeddingData}
                                            templateDetails={templateDetails}
                                        />
                                    </Suspense>
                                    :null
                            : null}
                </div>
                :null}


            <Modal
                classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-no-top',
                    closeButton: 'modal-close-button',
                }}
                open={showChangeTypeWarningModal}
                focusTrapped={false}
                onClose={() => {
                   setShowChangeTypeWarningModal(false)
                }}
            >
                <div style={{marginTop: '2.5rem', marginBottom: '1rem'}}>
                    <h6>Atenționare!</h6>
                    <br/>
                    <p>Schimbarea tipului de animație va duce la pierderea configurărilor actuale. <br/>Sunteți sigur/ă că doriți să continuați?</p>
                </div>

                <BackAndConfirmButtons
                    outerStyle={{width: '100%', margin: '20px 0 0 0'}}
                    textBack={"Nu"}
                    textConfirm={"Da, continui"}

                    functionBack={() => setShowChangeTypeWarningModal(false)}
                    functionConfirm={() => {
                        setShowChangeTypeWarningModal(false)
                        setShowAnimationsSelector(true)
                    }}
                />
            </Modal>

        </div>
    )
}
